import { useContext, useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { Icon } from "@mui/material";

import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

import BlogForm from "../blogForm";
import DataTable from "customComponents/Tables/DataTable";
import { getPlaceholderImageURL } from "utils/util";
import { deletePost } from "services/blogPostService";
import { sendNewsletter } from "services/subscriberService";

export default function BlogDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [blog, setBlog] = useState("");
	const [blogs, setBlogs] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	useEffect(() => {
		setBlogs(props.blogs);
	}, [props.blogs]);

	const handleClickOpen = (item) => {
		setBlogs(blogs);
		setBlog(item);
		setOpen(true);
	};

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const handleConfirmationOpen = (item) => {
		setBlog(item);
		setConfirmationOpen(true);
	};

	const handleBlogDelete = async (item) => {
		try {
			const response = await deletePost(item.id);
			if (response) {
				setBlogs(blogs.filter((x) => x.id !== item.id));
				showNotification("The post was successfully removed.", "success");
				setConfirmationOpen(false);
			}
		} catch (err) {
			showNotification("There was a problem removing the post. Please try again.", "error");
		}
	};

	const handleSendNewsletter = async (id) => {
		try {
			const response = await sendNewsletter(id);
			if (response) {
				showNotification(response.message, "success");
			}
		} catch (err) {
			showNotification("Sending e-mail to subscribers failed.", "error");
		}
	};

	const ImageRow = ({ title, id, image }) => {
		const [imageSrc, setImageSrc] = useState(getPlaceholderImageURL());

		useEffect(() => {
			const img = new Image();
			img.src = id ? image : getPlaceholderImageURL();
			img.onload = () => setImageSrc(img.src);
		}, [id]);

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1}>
				<img
					style={{
						width: "75px",
						height: "auto",
						maxHeight: "75px",
						minHeight: "75px",
						objectFit: "contain",
					}}
					src={imageSrc}
					alt={title}
				/>
			</MDBox>
		);
	};

	return (
		<>
			<DataTable
				table={{
					columns: [
						{ Header: "", accessor: "image", width: "100px", align: "center" },
						{ Header: "title", accessor: "title", width: "*", align: "left" },
						{ Header: "tags", accessor: "tags", align: "center" },
						{ Header: "last updated", accessor: "updatedAt", align: "center" },
						{ Header: "actions", accessor: "actions", align: "center" },
					],
					rows:
						blogs?.length > 0
							? blogs?.map((item) => ({
									image: <ImageRow title={item.title} image={item.image} id={item.id} />,
									title: (
										<MDTypography color="text" variant="p">
											{item.title}
										</MDTypography>
									),
									tags: (
										<MDTypography color="text" variant="p">
											{item.tags}
										</MDTypography>
									),
									updatedAt: (
										<MDTypography color="text" variant="p">
											{item.updatedAt}
										</MDTypography>
									),
									actions: (
										<MDBox lineHeight={1} textAlign="left" sx={{ display: "flex", gap: "8px" }}>
											<MDButton
												iconOnly={true}
												onClick={(e) => handleConfirmationOpen(item)}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium">
												<Icon>delete</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="primary"
												fontWeight="medium"
												onClick={(e) => handleClickOpen(item)}>
												<Icon>edit</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="success"
												fontWeight="medium"
												onClick={(e) => handleSendNewsletter(item.id)}>
												<Icon fontSize="medium">send</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				aria-labelledby="customized-dialog-title"
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<BlogForm
						blog={blog}
						setBlog={setBlog}
						blogs={blogs}
						setBlogs={setBlogs}
						open={open}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				minWidth={"200px"}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this post? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleBlogDelete(blog)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
		</>
	);
}
