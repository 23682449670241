import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.png";
import { signIn } from "services/userService";
import { useNavigate } from "react-router-dom";
import { Dialog, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { setAuthToken } from "utils/authToken";

function Basic() {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState(null);
	const [openLoginError, setOpenLoginError] = useState(false);
	const navigate = useNavigate();

	const handleClose = () => {
		setOpenLoginError(false);
	};

	const handleFormKeyPress = (event) => {
		if (event.key === "Enter") {
			handleSignIn(event);
		}
	};

	const handleDialogKeyPress = (event) => {
		if (event.key === "ESC") {
			handleClose();
		}
	};

	const handleSignIn = async (event) => {
		event?.preventDefault();
		const userData = {
			email: email,
			password: password,
		};

		try {
			const response = await signIn(userData);
			setAuthToken(response.token);
			if (response) {
				navigate("/newsletter");
			}
		} catch (err) {
			setErrorMessage(err.response.data.message);
			setOpenLoginError(true);
		}
	};

	return (
		<BasicLayout image={bgImage}>
			<Card>
				<MDBox
					variant="gradient"
					bgColor="dark"
					borderRadius="lg"
					coloredShadow="dark"
					mx={2}
					mt={-3}
					p={2}
					mb={1}
					textAlign="center">
					<MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
						Sign In
					</MDTypography>
				</MDBox>
				<MDBox pt={4} pb={3} px={3}>
					<MDBox component="form" role="form">
						<MDBox mb={2}>
							<MDInput
								type="email"
								label="E-mail"
								defaultValue={email}
								onChange={(event) => setEmail(event.target.value)}
								fullWidth
							/>
						</MDBox>
						<MDBox mb={2}>
							<MDInput
								type="password"
								label="Password"
								defaultValue={password}
								onChange={(event) => setPassword(event.target.value)}
								fullWidth
							/>
						</MDBox>
						{/* <MDBox display="flex" alignItems="center" ml={-1}>
							<Switch checked={rememberMe} onChange={handleSetRememberMe} />
							<MDTypography
								variant="button"
								fontWeight="regular"
								color="text"
								onClick={handleSetRememberMe}
								sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}>
								&nbsp;&nbsp;Remember me
							</MDTypography>
						</MDBox> */}
						<MDBox mt={4} mb={1}>
							<MDButton
								type="submit"
								variant="gradient"
								color="info"
								fullWidth
								onClick={handleSignIn}
								onKeyPress={handleFormKeyPress}>
								Connect
							</MDButton>
						</MDBox>
						<Dialog fullScreen={fullScreen} open={openLoginError} onClose={handleClose}>
							<MDBox py={2}>
								<MDTypography pb={3} px={3} variant="h5">
									{errorMessage}
								</MDTypography>
								<MDBox px={3} sx={{ display: "flex", justifyContent: "center", gap: "8px" }}>
									<MDButton
										variant="contained"
										color="primary"
										onClick={handleClose}
										onKeyPress={handleDialogKeyPress}>
										OK
									</MDButton>
								</MDBox>
							</MDBox>
						</Dialog>
					</MDBox>
				</MDBox>
			</Card>
		</BasicLayout>
	);
}

export default Basic;
