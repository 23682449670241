import React, { useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";

// Data
import TestimonialDataTable from "./data/TestimonialDataTable";
import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { getTestimonials } from "services/testimonialService";
import TestimonialForm from "./testimonialForm";

function Testimonial() {
	const showNotification = useContext(SnackbarContext);
	const [testimonials, setTestimonials] = useState([]);
	const [open, setOpen] = useState(false);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	const testimonialInitialValues = {
		author: "",
		title: "",
		date: "",
		image: "",
		details: "",
	};

	useEffect(() => {
		async function fetchTestimonials() {
			try {
				const result = await getTestimonials();
				if (result) {
					setTestimonials(result);
				}
			} catch (err) {
				showNotification("Fetch testimonials failed", "error");
			}
		}
		fetchTestimonials();
	}, []);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const sendOpenState = () => {
		setOpen(false);
	};

	return (
		<DashboardLayout>
			<MDBox py={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Testimonials
								</MDTypography>
								<MDButton
									iconOnly={true}
									variant="contained"
									size="medium"
									color="primary"
									onClick={handleClickOpen}>
									<Icon fontSize="small">add</Icon>
								</MDButton>
							</MDBox>
							<MDBox pt={3}>
								<TestimonialDataTable testimonials={testimonials}></TestimonialDataTable>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<TestimonialForm
						setTestimonials={setTestimonials}
						testimonials={testimonials}
						setOpen={sendOpenState}
						initialValues={testimonialInitialValues}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
		</DashboardLayout>
	);
}

export default Testimonial;
