import api from "../utils/axios";

export const createTestimonial = async (testimonial, image) => {
	const formData = new FormData();
	testimonial.date = new Date();
	formData.append("testimonial", JSON.stringify(testimonial));

	if (image) {
		formData.append("image", image);
	}
	const response = await api.post("/testimonial", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});

	return response.data;
};

export const getTestimonials = async (page, pageSize) => {
	const response = await api.get("/testimonial", { params: { page, pageSize } });
	return response.data;
};

export const updateTestimonial = async (testimonial, image) => {
	const formData = new FormData();
	testimonial.date = new Date();

	formData.append("testimonial", JSON.stringify(testimonial));

	if (image) {
		formData.append("image", image);
	}

	const response = await api.put(`/testimonial/${testimonial.id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export const deleteTestimonial = async (id) => {
	const response = await api.delete(`/testimonial/${id}`);
	return response.data;
};
