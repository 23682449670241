import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, EditorState, convertToRaw } from "draft-js";

import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

function ContentEditor({ getContent, saveContent, label }) {
	const showNotification = useContext(SnackbarContext);
	const [content, setContent] = useState({});
	const [value, setValue] = useState(EditorState.createEmpty());
	const [initialValue, setInitialValue] = useState(EditorState.createEmpty());

	useEffect(() => {
		async function fetchContent() {
			const result = await getContent();
			if (result) {
				setContent(result);
				setEditorDefaultValue(result.value);
			}
		}
		fetchContent();
	}, [getContent]);

	const onEditorChange = (event) => {
		const content = event.getCurrentContent();
		setValue(event);
		setContent((prevState) => ({ ...prevState, value: content }));
	};

	const setEditorDefaultValue = (html) => {
		if (!html) return;
		const contentBlock = htmlToDraft(html);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
			const editorState = EditorState.createWithContent(contentState);
			setValue(editorState);
			setInitialValue(editorState);
		}
	};

	const saveContentSetting = async () => {
		let currentContent = null;
		if (!content.value) {
			showNotification("Content cannot be empty", "error");
			return;
		} else {
			currentContent = content.value;
		}

		try {
			let contentState;
			if (currentContent instanceof ContentState) {
				contentState = currentContent;
			} else {
				const contentBlock = htmlToDraft(currentContent);
				if (!contentBlock) {
					showNotification("Failed to convert HTML to Draft.js content.", "error");
					return;
				}
				contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			}

			const editorState = EditorState.createWithContent(contentState);

			const rawState = convertToRaw(editorState.getCurrentContent());
			const html = draftToHtml(rawState);

			if (rawState.blocks[0].text.trim() === "") {
				showNotification("Content cannot be empty", "error");
				return;
			}

			const response = await saveContent({ value: html });
			if (response?.message) {
				showNotification(response.message, "success");
			}
		} catch (error) {
			console.log(error);
			showNotification("There was a problem saving the content. Please try again.", "error");
		}
	};

	const resetContentSetting = async () => {
		setValue(initialValue);
	};

	return (
		<DashboardLayout>
			<MDBox py={3} display="flex" height="100%">
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								minHeight="85px"
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDBox>
									<MDTypography variant="h6" color="white" textAlign="middle">
										{label}
									</MDTypography>
								</MDBox>
								<MDBox
									sx={{
										display: "flex",
										gap: "8px",
									}}>
									<MDButton
										iconOnly={true}
										variant="contained"
										size="medium"
										color="secondary"
										sx={{
											display: "flex",
											gap: "4px",
										}}
										onClick={resetContentSetting}>
										<Icon fontSize="medium">restore</Icon>
									</MDButton>
									<MDButton
										iconOnly={true}
										variant="contained"
										size="medium"
										color="primary"
										sx={{
											display: "flex",
											gap: "4px",
										}}
										onClick={saveContentSetting}>
										<Icon fontSize="medium">save</Icon>
									</MDButton>
								</MDBox>
							</MDBox>
							<MDBox px={3} sx={{ minHeight: "350px", height: "100%" }} py={2}>
								<Editor
									editorStyle={{
										border: "1px solid #F1F1F1",
										borderTop: "none",
										borderBottomRightRadius: "4px",
										borderBottomLeftRadius: "4px",
										padding: "0 12px",
										minHeight: "250px",
										height: "100%",
									}}
									editorState={value}
									onEditorStateChange={onEditorChange}
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default ContentEditor;
