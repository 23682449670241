import React, { useContext, useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import ImageUpload from "components/MDImageUpload";

import { Icon } from "@mui/material";

import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import SimpleReactValidator from "simple-react-validator";

import { createPost, updatePost } from "services/blogPostService";

const BlogForm = (props) => {
	const validator = useRef(new SimpleReactValidator());
	const showNotification = useContext(SnackbarContext);
	const [title, setTitle] = useState("");
	const [subtitle, setSubtitle] = useState("");
	const [abstract, setAbstract] = useState("");
	const [content, setContent] = useState(EditorState.createEmpty());
	const [paragraphs, setParagraphs] = useState([EditorState.createEmpty()]);
	const [tags, setTags] = useState("");
	const [readTime, setReadTime] = useState("");
	const [titleError, setTitleError] = useState(false);
	const [tagsError, setTagsError] = useState(false);
	const [image, setImage] = useState(props.blog?.id ? props.blog.image : null);
	const [pdfFile, setPdfFile] = useState(null); // State to store the selected PDF file
	const [isFormChanged, setIsFormChanged] = useState(false);
	const pdfInputRef = useRef(null);

	useEffect(() => {
		if (props.blog) {
			setTitle(props.blog.title);
			setSubtitle(props.blog.subtitle);
			setAbstract(props.blog.abstract);
			setTags(props.blog.tags);
			setReadTime(props.blog.readTime);
			setEditorDefaultValue(props.blog.content, props.blog.paragraphs);
		}
	}, [props.blog]);

	const triggerPdfUpload = () => {
		if (pdfInputRef.current) {
			pdfInputRef.current.click();
		}
	};

	const handlePdfFileChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type === "application/pdf") {
			setPdfFile(file);
		} else {
			showNotification("Please select a valid PDF file", "error");
		}
	};

	const setEditorDefaultValue = (html, paragraphsArray) => {
		if (html) {
			const contentBlock = htmlToDraft(html);
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(
					contentBlock.contentBlocks,
					contentBlock.entityMap
				);
				setContent(EditorState.createWithContent(contentState));
			}
		}

		if (paragraphsArray && paragraphsArray.length > 0) {
			const editorStates = paragraphsArray.map((paragraph) => {
				const contentBlock = htmlToDraft(paragraph);
				if (contentBlock) {
					const contentState = ContentState.createFromBlockArray(
						contentBlock.contentBlocks,
						contentBlock.entityMap
					);
					return EditorState.createWithContent(contentState);
				}
				return EditorState.createEmpty();
			});
			setParagraphs(editorStates);
		}
	};

	const onEditorChange = (editorState, index) => {
		const newParagraphs = [...paragraphs];
		newParagraphs[index] = editorState;
		setParagraphs(newParagraphs);
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const addParagraph = () => {
		setParagraphs([...paragraphs, EditorState.createEmpty()]);
		setIsFormChanged(true);
	};

	const removeParagraph = (index) => {
		const newParagraphs = paragraphs.filter((_, i) => i !== index);
		setParagraphs(newParagraphs);
		setIsFormChanged(true);
	};

	const validateTitle = (event) => {
		setTitle(event.target.value);
		setTitleError(!validator.current.check(event.target.value, "required"));

		validator.current.showMessageFor("title");

		setIsFormChanged(true);

		props.sendData(isFormChanged);
	};

	const validateSubtitle = (event) => {
		setSubtitle(event.target.value);
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const validateAbstract = (event) => {
		setAbstract(event.target.value);
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const validateTags = (event) => {
		setTags(event.target.value);
		setTagsError(!validator.current.check(event.target.value, "required"));

		validator.current.showMessageFor("tags");

		setIsFormChanged(true);

		props.sendData(isFormChanged);
	};

	const validateReadTime = (event) => {
		setReadTime(event.target.value);
		setIsFormChanged(true);
		props.sendData(isFormChanged);
	};

	const handleBlog = async () => {
		const rawContentState = convertToRaw(content.getCurrentContent());
		const htmlContent = draftToHtml(rawContentState);

		const paragraphsHtml = paragraphs.map((editorState) => {
			const rawState = convertToRaw(editorState.getCurrentContent());
			return draftToHtml(rawState);
		});

		if (!validator.current.allValid()) {
			showNotification("Need to complete required fields", "error");
			return;
		}

		const blog = {
			title: title,
			subtitle: subtitle,
			abstract: abstract,
			content: htmlContent,
			paragraphs: paragraphsHtml,
			tags: tags,
			readTime: readTime,
		};

		let result = null;
		if (props.blog) {
			blog.id = props.blog.id;
			result = await updatePost(blog.id, blog, image, pdfFile);
		} else {
			result = await createPost(blog, image, pdfFile);
		}

		if (result) {
			const updatedBlogs = props.blogs.map((b) => (b.id === blog.id ? blog : b));
			if (!props.blog) {
				updatedBlogs.push(result);
			}
			props.setBlogs(updatedBlogs);
			props.setOpen(false);
		}
	};

	return (
		<>
			<MDTypography px={3} variant="caption" fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.blog && props.blog.id ? "Edit Post" : "Create Post"}
			</MDTypography>
			<MDBox pb={2} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							gap: "8px",
							flexDirection: "row",
						}}>
						<MDBox
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								width: "65%",
								flexDirection: "column",
							}}>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Title"
									value={title}
									error={titleError}
									onChange={(event) => validateTitle(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("title", title, "required")}
								</MDTypography>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Subtitle"
									value={subtitle}
									onChange={(event) => validateSubtitle(event)}
									fullWidth
								/>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Abstract"
									value={abstract}
									onChange={(event) => validateAbstract(event)}
									fullWidth
								/>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="text"
									label="Tags"
									error={tagsError}
									value={tags}
									onChange={(event) => validateTags(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("tags", tags, "required")}
								</MDTypography>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<MDInput
									type="number"
									label="Read Time (minutes)"
									value={readTime}
									onChange={(event) => validateReadTime(event)}
									fullWidth
								/>
							</MDBox>
							<MDBox mb={2} sx={{ width: "100%" }}>
								<input
									type="file"
									ref={pdfInputRef}
									style={{ display: "none" }} // Hidden input field
									accept="application/pdf"
									onChange={handlePdfFileChange}
								/>
								<MDButton variant="contained" color="primary" onClick={triggerPdfUpload}>
									Upload PDF
								</MDButton>
								{pdfFile && (
									<MDTypography sx={{ mt: 1, fontSize: "12px" }}>{pdfFile.name}</MDTypography>
								)}
							</MDBox>
						</MDBox>
						<MDBox
							mb={2}
							sx={{
								width: "35%",
								justifyContent: "center",
								display: "flex",
								alignItems: "center",
								minHeight: "150px",
							}}>
							<ImageUpload defaultImage={image} onImageUpload={(image) => setImage(image)} />
						</MDBox>
					</MDBox>
					{/* Paragraph Editors */}
					{paragraphs.map((paragraph, index) => (
						<MDBox key={index} sx={{ minHeight: "200px", mt: 2 }}>
							<Editor
								editorStyle={{
									border: "1px solid #F1F1F1",
									borderTop: "none",
									borderBottomRightRadius: "4px",
									borderBottomLeftRadius: "4px",
									padding: "0 12px",
									minHeight: "150px",
								}}
								editorState={paragraph}
								onEditorStateChange={(editorState) => onEditorChange(editorState, index)}
							/>
							<MDButton
								variant="text"
								color="error"
								onClick={() => removeParagraph(index)}
								sx={{ mt: 1 }}>
								Remove Paragraph
							</MDButton>
						</MDBox>
					))}

					<MDButton variant="text" color="primary" onClick={addParagraph} sx={{ mt: 2 }}>
						Add Paragraph
					</MDButton>

					{/* Main Content Editor */}
					<MDBox sx={{ minHeight: "350px" }} py={2}>
						<Editor
							editorStyle={{
								border: "1px solid #F1F1F1",
								borderTop: "none",
								borderBottomRightRadius: "4px",
								borderBottomLeftRadius: "4px",
								padding: "0 12px",
								minHeight: "250px",
							}}
							editorState={content}
							onEditorStateChange={setContent}
						/>
					</MDBox>
					<MDTypography sx={{ color: "red", fontSize: "12px" }}>
						{validator.current.message(
							"content",
							convertToRaw(content.getCurrentContent()).blocks[0].text,
							"required"
						)}
					</MDTypography>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton type="button" variant="contained" size="medium" color="primary" onClick={handleBlog}>
							<Icon fontSize="large">save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</>
	);
};

export default BlogForm;
