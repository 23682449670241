import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

export default function data(settings) {
	const changeSetting = (event, item) => {
		item.value = event.target.value;
	};

	return {
		columns: [
			{ Header: "Setting", accessor: "key", width: "*", align: "left" },
			{ Header: "Value", accessor: "value", width: "*", align: "left" },
		],

		rows: settings.map((item) => ({
			key: (
				<MDTypography
					display="block"
					textTransform="capitalize"
					variant="caption"
					color="text"
					fontWeight="medium">
					{item.label}
				</MDTypography>
			),
			value: (
				<MDBox sx={{ width: "100%" }} lineHeight={1} textAlign="left">
					<MDInput
						style={{ width: "100%", minWidth: "350px" }}
						defaultValue={item.value}
						onChange={(event) => changeSetting(event, item)}></MDInput>
				</MDBox>
			),
		})),
	};
}
