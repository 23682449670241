import { useState, useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Sidenav from "customComponents/Sidenav";
import Configurator from "customComponents/Configurator";

import theme from "assets/theme";

import themeDark from "assets/theme-dark";

import routes from "routes";

import { useMaterialUIController, setMiniSidenav } from "context";

import { SnackbarProvider } from "components/MDNotification";

export default function App() {
	const [controller, dispatch] = useMaterialUIController();
	const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
	const [onMouseEnter, setOnMouseEnter] = useState(false);
	const { pathname } = useLocation();

	// Open sidenav when mouse enter on mini sidenav
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	const isConnected = () => {
		return !!localStorage.getItem("token");
	};

	// Close sidenav when mouse leave mini sidenav
	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	// Setting the dir attribute for the body element
	useEffect(() => {
		document.body.setAttribute("dir", direction);
	}, [direction]);

	// Setting page scroll to 0 when changing the route
	useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
	}, [pathname]);

	const getRoutes = (allRoutes) =>
		allRoutes.map((route) => {
			if (route.collapse) {
				return getRoutes(route.collapse);
			}

			if (route.route) {
				return <Route exact path={route.route} element={route.component} key={route.key} />;
			}

			return null;
		});

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<SnackbarProvider>
				<CssBaseline />
				{layout === "dashboard" && (
					<>
						<Sidenav
							color={sidenavColor}
							brandName="SIEL.AI"
							routes={routes}
							onMouseEnter={handleOnMouseEnter}
							onMouseLeave={handleOnMouseLeave}
						/>
						<Configurator />
					</>
				)}
				<Routes>
					{getRoutes(routes)}
					<Route
						path="*"
						element={isConnected() ? <Navigate to="/newsletter" /> : <Navigate to="/connect" />}
					/>
				</Routes>
			</SnackbarProvider>
		</ThemeProvider>
	);
}
