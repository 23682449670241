import React, { useState, useEffect, useContext, useRef } from "react";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { Icon } from "@mui/material";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import ImageUpload from "components/MDImageUpload";
import SimpleReactValidator from "simple-react-validator";
import { updateTestimonial } from "services/testimonialService";
import { createTestimonial } from "services/testimonialService";
import { getPlaceholderImageURL } from "utils/util";

const TestimonialForm = (props) => {
	const showNotification = useContext(SnackbarContext);
	const validator = useRef(new SimpleReactValidator());
	const [author, setAuthor] = useState({ value: "", error: false });
	const [title, setTitle] = useState({ value: "", error: false });
	const [company, setCompany] = useState({ value: "", error: false });
	const [description, setDescription] = useState({ value: "", error: false });

	const [image, setImage] = useState(
		props.currentTestimonial?.id ? props.currentTestimonial.image : getPlaceholderImageURL()
	);

	useEffect(() => {
		if (props.currentTestimonial) {
			setTitle({ value: props.currentTestimonial.title, error: false });
			setDescription({ value: props.currentTestimonial.description, error: false });
			setAuthor({ value: props.currentTestimonial.author, error: false });
			setCompany({ value: props.currentTestimonial.company, error: false });
		}
	}, [props, props.currentTestimonial]);

	useEffect(() => {
		const currentValidator = validator.current;
		return () => currentValidator.hideMessages(); // cleanup function
	}, []);

	const handleTestimonial = async () => {
		let result = null;
		const testimonial = {
			title: title.value,
			description: description.value,
			author: author.value,
			company: company.value,
		};
		if (!validator.current.allValid()) {
			showNotification("You need to complete all required fields");
			return;
		}
		if (props.currentTestimonial) {
			testimonial.id = props.currentTestimonial.id;
			try {
				result = await updateTestimonial(testimonial, image);
				showNotification("Success edit testimonial", "success");
			} catch (err) {
				if (err.response?.data?.message) {
					showNotification(err.response?.data?.message, "error");
				} else {
					showNotification("Error edit testimonial", "error");
				}
			}
		} else {
			try {
				result = await createTestimonial(testimonial, image);
				showNotification("Success create testimonial", "success");
			} catch (err) {
				if (err.response?.data?.message) {
					showNotification(err.response?.data?.message, "error");
				} else {
					showNotification("Error create testimonial", "error");
				}
			}
		}

		if (result) {
			const copyTestimonials = JSON.parse(JSON.stringify(props.testimonials));
			const currentTestimonialIndex = copyTestimonials.findIndex((p) => p.id === result.id);
			if (currentTestimonialIndex !== -1) {
				copyTestimonials[currentTestimonialIndex] = result;
			} else {
				copyTestimonials.push(result);
			}
			props.setTestimonials(copyTestimonials);
			props.setOpen(false);
		}
	};

	const validateTitleField = (event) => {
		setTitle({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("title");
	};

	const validateDescriptionField = (event) => {
		setDescription({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("description");
	};

	const validateAuthorField = (event) => {
		setAuthor({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("author");
	};

	const validateCompanyField = (event) => {
		setCompany({ value: event.target.value, error: !validator.current.check(event.target.value, "required") });
		validator.current.showMessageFor("company");
	};

	return (
		<MDBox sx={{ width: "100%" }}>
			<MDTypography variant="caption" px={3} fontWeight="bold" style={{ fontSize: "25px" }}>
				{props.currentTestimonial && props.currentTestimonial.id ? "Edit Testimonial" : "Create Testimonial"}
			</MDTypography>
			<MDBox pt={4} pb={3} px={3}>
				<MDBox component="form" role="form">
					<MDBox
						sx={{
							display: "flex",
							justifyContent: "center",
							width: "100%",
							alignItems: "center",
							gap: "8px",
						}}>
						<MDBox mb={2} sx={{ width: "65%" }}>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Title"
									value={title.value}
									error={title.error}
									onChange={(event) => validateTitleField(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("title", title.value, "required")}
								</MDTypography>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Author"
									value={author.value}
									error={author.error}
									onChange={(event) => validateAuthorField(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("author", author.value, "required")}
								</MDTypography>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Company"
									value={company.value}
									error={company.error}
									onChange={(event) => validateCompanyField(event)}
									fullWidth
								/>
								<MDTypography sx={{ color: "red", fontSize: "12px" }}>
									{validator.current.message("company", company.value, "required")}
								</MDTypography>
							</MDBox>
						</MDBox>
						<MDBox
							mb={2}
							sx={{ width: "35%", justifyContent: "center", display: "flex", alignItems: "center" }}>
							<ImageUpload defaultImage={image} onImageUpload={(image) => setImage(image)}></ImageUpload>
						</MDBox>
					</MDBox>
					<MDBox mb={2}>
						<MDInput
							type="text"
							label="Description"
							value={description.value}
							error={description.error}
							multiline
							rows={5}
							onChange={(event) => validateDescriptionField(event)}
							fullWidth
						/>
						<MDTypography sx={{ color: "red", fontSize: "12px" }}>
							{validator.current.message("description", description.value, "required")}
						</MDTypography>
					</MDBox>
					<MDBox mt={2} sx={{ display: "flex", width: "100%", justifyContent: "end" }}>
						<MDButton type="button" variant="contained" color="primary" onClick={handleTestimonial}>
							<Icon fontSize="medium">save</Icon>
							SAVE
						</MDButton>
					</MDBox>
				</MDBox>
			</MDBox>
		</MDBox>
	);
};

export default TestimonialForm;
