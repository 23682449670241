import api from "../utils/axios";

export const getSettings = async () => {
	const response = await api.get(`/settings`);
	return response.data;
};

export const getSetting = async (key) => {
	const response = await api.get(`/settings/${key}`);
	return response.data;
};

export const createOrUpdateSetting = async (setting) => {
	const response = await api.post("/settings", setting);
	return response.data;
};

export async function getPrivacyPolicy() {
	try {
		const response = await api.get("/settings/privacy-policy");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function savePrivacyPolicy(data) {
	const response = await api.post(`/settings/privacy-policy`, {
		value: data.value,
	});
	return response.data;
}

export async function getTermsAndConditions() {
	try {
		const response = await api.get("/settings/terms-and-conditions");
		return response.data;
	} catch (error) {
		return [];
	}
}

export async function saveTermsAndConditions(data) {
	const response = await api.post(`/settings/terms-and-conditions`, {
		value: data.value,
	});
	return response.data;
}
