import React, { useContext, useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

import DataTable from "customComponents/Tables/DataTable";

import { Icon } from "@mui/material";

import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";

import { deleteTestimonial } from "services/testimonialService";

import TestimonialForm from "../testimonialForm";
import { getPlaceholderImageURL } from "utils/util";

export default function TestimonialDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [open, setOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [testimonial, setTestimonial] = useState({});
	const [testimonials, setTestimonials] = useState([]);
	const [isFormChanged, setIsFormChanged] = useState(false);

	const getIsFormChanged = (data) => {
		setIsFormChanged(data);
	};

	useEffect(() => {
		setTestimonials(props.testimonials);
	}, [props.testimonials]);

	const handleClickOpen = (data) => {
		setTestimonial(data);
		setOpen(true);
	};

	const handleConfirmationOpen = (data) => {
		setTestimonial(data);
		setConfirmationOpen(true);
	};

	const handleDelete = async (item) => {
		try {
			const response = await deleteTestimonial(item.id);
			if (response) {
				setTestimonials(testimonials.filter((x) => x.id !== item.id));
				setConfirmationOpen(false);
				showNotification("Testimonial was successfully removed.", "success");
			}
		} catch (err) {
			showNotification("There was a problem removing the testimonial. Please try again.", "error");
		}
	};

	const Testimonial = ({ image, author }) => {
		const [isLoading, setIsLoading] = useState(true);

		useEffect(() => {
			const img = new Image();
			img.src = image;
			img.onload = () => setIsLoading(false);
		}, [image]);

		return (
			<MDBox display="flex" alignItems="center" lineHeight={1}>
				<img
					style={{
						width: "75px",
						height: "auto",
						maxHeight: "75px",
						minHeight: "75px",
						objectFit: "contain",
					}}
					src={isLoading ? getPlaceholderImageURL() : image}
					alt={isLoading ? "Placeholder" : author}></img>
			</MDBox>
		);
	};

	const columns = [
		{ Header: "", accessor: "image", align: "center" },
		{ Header: "title", accessor: "title", align: "left" },
		{ Header: "author", accessor: "author", align: "left" },
		{ Header: "company", accessor: "company", align: "left" },
		{ Header: "created at", accessor: "date", align: "center" },
		{ Header: "actions", accessor: "action", align: "center" },
	];

	return (
		<>
			<DataTable
				table={{
					columns,
					rows:
						testimonials?.length > 0
							? testimonials?.map((item) => ({
									image: (
										<Testimonial
											image={item.id ? item.image : getPlaceholderImageURL()}
											author={item.author}
										/>
									),
									author: (
										<MDTypography variant="p" color="text">
											{item.author}
										</MDTypography>
									),
									company: (
										<MDTypography variant="p" color="text">
											{item.company}
										</MDTypography>
									),
									title: (
										<MDTypography variant="p" color="text">
											{item.title}
										</MDTypography>
									),
									date: (
										<MDTypography variant="p" color="text">
											{item.date}
										</MDTypography>
									),
									action: (
										<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium"
												onClick={(e) => handleConfirmationOpen(item)}>
												<Icon fontSize="medium">delete</Icon>
											</MDButton>
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="primary"
												fontWeight="medium"
												onClick={(e) => handleClickOpen(item)}>
												<Icon fontSize="medium">edit</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				open={open}
				setOpen={setOpen}
				isFormChanged={isFormChanged}
				setIsFormChanged={setIsFormChanged}
				component={
					<TestimonialForm
						testimonials={testimonials}
						setTestimonials={setTestimonials}
						currentTestimonial={testimonial}
						setTestimonial={setTestimonial}
						setOpen={setOpen}
						isFormChanged={isFormChanged}
						setIsFormChanged={setIsFormChanged}
						sendData={getIsFormChanged}
					/>
				}></ResponsiveDialog>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this testimonial? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleDelete(testimonial)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
		</>
	);
}
